<template>
  <div class="assigned-input">
      <type-select v-model="model" class="es-simple only-text" :disable="disable" />
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import TaskHelper from "components/suporte/components/task/include/TaskHelper"
import TaskZoneInput from "components/suporte/components/task/include/TaskZoneInput"
import {updateTask} from "@/domain/tarefas/services"
import TypeSelect from "components/suporte/components/task/include/TypeSelect"

export default {
  name: "TypeEdit",
  props: {
    disable: {
      type: Boolean,
      default: true
    }
  },
  mixins: [TaskZoneInput, TaskMixin, TaskHelper],
  methods: {
    __reset () {
      this.model = this.task.type
    },
    change (v) {
      this.$uloc.dialog({
        title: 'Confirmar alteração do tipo',
        message: !v ? 'Remover o tipo desta tarefa?' : 'Alterar tipo da tarefa para ' + v.name + '?',
        ok: 'Confirmar alteração',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      })
          .then(() => {
            const old = this.task.deadline
            this.loading = true
            updateTask({
              id: this.task.id,
              type: v ? v.id : null
            }, 'PATCH')
                .then(response => {
                  this.loading = false
                  this.reset()
                  this.$emit('update', v)
                })
                .catch(error => {
                  this.loading = false
                  this.task.type = v
                  this.alertApiError(error)
                })
            this.task.type = v
          })
          .catch(() => {
          })
    }
  },
  components: {TypeSelect}
}
</script>

