<template>
  <div class="stask-zone-input">
    <div class="input-fake" v-if="task.priority">
      <div class="flex items-center no-select">
        <priority-icon :priority="task.priority" /> <div class="m-l-sm">{{task.priority.name}}</div>
      </div>
    </div>
    <div class="input-fake" v-else>Indefinido</div>
    <u-popover v-if="!disable" ref="popup" class="window-context-menu min" anchor="bottom left" self="top left">
      <ul @click="click">
        <li v-for="s in listActives" :key="s.id">
          <a @click="confirm(s)" class="menuItem">
            <div class="flex items-center no-select">
              <priority-icon :priority="s" /> <div class="m-l-sm">{{s.name}}</div>
            </div>
          </a>
        </li>
<!--        <li class="diviser"></li>-->
      </ul>
    </u-popover>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import {listPriority as list, updateTask} from "@/domain/tarefas/services"
import {UPopover} from "uloc-vue"
import PriorityIcon from "components/suporte/components/task/include/PriorityIcon"

export default {
  name: "TaskPriority",
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      list: []
    }
  },
  mixins: [TaskMixin],
  components: {
    PriorityIcon,
    UPopover
  },
  mounted () {
    this.load()
  },
  computed: {
    listActives () {
      return this.list.filter(s => !this.task.priority || s.id !== this.task.priority.id)
    }
  },
  methods: {
    load () {
      list (100, 1, 'active=1')
          .then(response => {
            this.list = response.data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    confirm (priority) {
      this.$uloc.dialog({
        title: 'Alterar prioridade',
        message: 'Alterar prioridade da tarefa para ' + priority.name + '?',
        ok: 'Alterar',
        cancel: 'Cancelar',
        color: 'black',
        esc: true,
        noCaps: true
      }).then(() => {
        this.altera(priority)
      })
        .catch(() => {})
    },
    altera (priority) {
      const old = this.task.priority
      this.loading = true
      updateTask({
        id: this.task.id,
        priority: priority.id
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.$emit('update', priority)
          })
          .catch(error => {
            this.loading = false
            this.task.priority = old
            this.alertApiError(error)
          })
      this.task.priority = priority
    },
    closeMenu(e) {
      this.$refs.popup.hide()
    },
    click(e) {
      this.closeMenu(e)
      this.$emit('click', e)
    }
  }
}
</script>
