<template>
  <div class="stask-details">
    <task-right-tab title="Informações">
      <div class="detail sperson">
        <div class="lbl">Responsável</div>
        <div class="value">
          <responsavel-edit disable/>
        </div>
      </div>
      <div class="detail sperson">
        <div class="lbl">Relator</div>
        <div class="value sperson">
          <author-edit disable />
        </div>
      </div>
      <div class="detail sinput">
        <div class="lbl" :class="{'flex itens-center': task.type}">
          <div>
            Tipo de requisição
          </div>
        </div>
        <div class="value sperson">
          <type-edit disable/>
        </div>
      </div>
      <div class="detail">
        <div class="lbl flex items-center">Prioridade</div>
        <div class="value">
          <task-priority disable/>
        </div>
      </div>
      <div class="detail sinput">
        <div class="lbl" :class="{'flex itens-center': task.type}">
          <div>
            Fila
          </div>
        </div>
        <div class="value sperson">
          <queue-edit disable />
        </div>
      </div>
      <div class="detail" v-if="task.queue">
        <div class="lbl">
          <div>
            Posição na fila
          </div>
        </div>
        <div class="value simple no-select">
          <span class="u-chip" v-if="task.queuePosition">{{ task.queuePosition }}</span>
          <span v-else>-</span>
        </div>
      </div>
      <div class="detail m-t-md" v-if="task.startTime">
        <div class="lbl">
          <div>
            Início do trabalho
          </div>
        </div>
        <div class="value simple no-select">
          <span>{{ task.startTime.date|formatDateDistance }}</span>
        </div>
      </div>
      <div class="detail sinput">
        <div class="lbl" :class="{'flex itens-center': task.deadline}">
          <div>
            Data limite
            <div class="font-11 text-blue-grey">Previsão de conclusão</div>
          </div>
        </div>
        <div class="value">
          <deadline disable />
        </div>
      </div>
      <div class="detail">
        <div class="lbl">
          <div>
            Progresso
          </div>
        </div>
        <div class="value simple no-select">
          <task-progress/>
        </div>
      </div>
    </task-right-tab>
    <task-right-tab title="Trabalho">
      <div class="detail hour-tab double-column">
        <div class="unique">
          <div class="lbl m-b-xs">Esforço total
            <u-icon name="question-circle" type="fa" color="grey-5" class="fa-fw helper">
              <u-tooltip :offset="[10,10]">Tempo necessário em horas para entregar a tarefa. Para adicionar minutos
                utilize h ou :
              </u-tooltip>
            </u-icon>
          </div>
          <div class="value">
            <div><span>{{ task.expectedHours }}</span></div>
            <hour-input v-if="false" v-model="task.expectedHours"/>
          </div>
        </div>
        <div class="unique">
          <div class="lbl m-b-xs">Tempo trabalhado
            <u-icon name="question-circle" type="fa" color="grey-5" class="fa-fw helper">
              <u-tooltip :offset="[10,10]">Soma das horas trabalhadas de todos os envolvidos na tarefa</u-tooltip>
            </u-icon>
          </div>
          <div class="value">
            <div><span>{{ task.workedHours }}</span></div>
          </div>
        </div>
      </div>
    </task-right-tab>
    <task-right-tab title="Projeto">
      <div class="detail sperson">
        <div class="lbl">Projeto</div>
        <div class="value">
          <project-edit disable />
        </div>
      </div>
      <div class="detail sperson">
        <div class="lbl">Cliente</div>
        <div class="value">
          <cliente-edit disable />
        </div>
      </div>
    </task-right-tab>
    <div class="text-grey font-11 m-t">
      Criado {{ task.createdAt|formatDate }}
      <div v-if="task.dateModified" class="m-t-xxs">Atualizado {{ task.dateModified.date|formatDateDistance }}</div>
    </div>
  </div>
</template>

<script>
import {UTooltip} from "uloc-vue"
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import TaskHelper from "components/suporte/components/task/include/TaskHelper"
import AuthorEdit from "components/suporte/components/task/include/AuthorEdit"
import ResponsavelEdit from "components/suporte/components/task/include/ResponsavelEdit"
import Deadline from "components/suporte/components/task/include/Deadline"
import TaskPriority from "components/suporte/components/task/include/Priority"
import TypeEdit from "components/suporte/components/task/include/TypeEdit"
import QueueEdit from "components/suporte/components/task/include/QueueEdit"
import ClienteEdit from "components/suporte/components/task/include/ClienteEdit"
import ProjectEdit from "components/suporte/components/task/include/ProjectEdit"
import TaskRightTab from "components/suporte/components/task/include/TaskRightTab"
import TaskProgress from "components/suporte/components/task/include/Progress"
import HourInput from "components/suporte/components/task/include/HourInput"

export default {
  name: "TaskDetalhes",
  components: {
    TaskProgress,
    TaskRightTab,
    ProjectEdit,
    ClienteEdit,
    QueueEdit,
    TypeEdit,
    TaskPriority,
    Deadline,
    ResponsavelEdit,
    AuthorEdit,
    UTooltip,
    HourInput
  },
  mixins: [TaskMixin, TaskHelper],
}
</script>
