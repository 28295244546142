<script>
import {find} from "@/domain/tarefas/services"
// import SlLoading from "components/layout/components/Loading"
import {UFakeLoader, UTooltip} from "uloc-vue"
// import EBtnTableOptions from "@/plugins/uloc-erp/components/button/EBtnTableOption"
import FollowTask from "@/components/suporte/components/task/include/Follow"
import TaskNameInput from "components/suporte/components/task/include/TaskNameInput"
import Author from "components/suporte/components/task/include/Author"
import TaskDescription from "components/suporte/components/task/include/Descricao"
import TaskActivity from "components/suporte/components/task/include/Atividade"
import TaskAttachments from "components/suporte/components/task/include/Anexos"
import TaskMenuRight from "components/suporte/components/task/include/TaskMenuRight"
import TaskStatusBtn from "components/suporte/components/task/include/StatusBtn"
import TaskDetalhes from "components/suporte/components/task/include/Detalhes"
// import TaskWork from "components/suporte/components/task/include/Work"

export default {
  name: 'TarefaPage',
  provide: function () {
    return {
      taskContainer: this
    }
  },
  inject: ['container'],
  mixins: [],
  components: {
    // TaskWork,
    TaskDetalhes,
    TaskStatusBtn,
    TaskMenuRight,
    TaskAttachments,
    TaskActivity,
    TaskDescription,
    Author,
    TaskNameInput,
    UFakeLoader,
    // UTooltip,
    FollowTask
  },
  data () {
    return {
      routeName: ['suporte.projeto.tarefa', 'suporte.tarefa', 'suporte.tarefas.fila.tarefa'],
      menuActive: {
        name: 'opened',
        label: 'Em aberto',
      },
      loading: true,
      task: null
    }
  },
  mounted() {
    if (this.container && this.container.$refs.menuProjeto) {
      this.container.$refs.menuProjeto.menu = 'fila'
    }
    document.body.classList.add('stask-body')
    this.$on('update', () => {
          find(this.$route.params.tarefa)
              .then(response => {
                this.task = response.data
              })
    })
    this.load()
  },
  beforeDestroy() {
    document.body.classList.remove('stask-body')
  },
  computed: {
    isClosed() {
      return this.task.internalStatus === 'closed'
    }
  },
  watch: {
    '$route.params.tarefa' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.tarefa)
          .then(response => {
            this.task = response.data
            setTimeout(() => {
              this.$options.meta.title = `#${this.task.id} ${this.task.description} - Tarefas`
            }, 2000)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    loadQueue (queue) {
      console.log('Load queue: ', queue)
    },
    updateFollowers (followers) {
      console.log(followers)
    }
  },
  meta: {
    title: 'Tarefa',
    name: 'Tarefa'
  }
}
</script>

<template>
  <div v-if="loading" style="padding: 20px">
    <div class="full-width flex justify-between">
      <div>
        <u-fake-loader width="200px" />
        <u-fake-loader width="100px" height="60px" />
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="200px" />
        <u-fake-loader width="200px" />
      </div>
      <div class="text-right">
        <u-fake-loader width="200px" /><u-fake-loader />
        <u-fake-loader />
        <br>
        <br>
        <u-fake-loader />
        <u-fake-loader />
      </div>
    </div>
    <br>
    <br>
    <br>
    <u-fake-loader width="50%" />
  </div>
  <div v-else-if="routeName.includes($route.name)" class="starefa-page">
    <div class="task-contanier">
      <div class="flex items-center nav-with-btn task-bar-nav">
        <u-btn @click="$router.go(-1)" class="nab-btn" icon="chevron-left" dense icon-type="fa" label="Voltar" no-caps flat color="blue-grey" />
        <div class="nav-container">
          <ul class="snav">
            <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
            <li v-if="task.project"><a @click="$router.push({name: 'suporte.projetos'})">{{ task.project.name }}</a></li>
            <li v-if="task.queue" class="disable cursor-pointer">{{ task.queue.name }}</li>
            <li class="disable cursor-pointer td-limit l-200">{{ task.description }}</li>
          </ul>
        </div>
      </div>
      <div class="task-content">
        <task-name-input />
        <author />
        <task-description />
        <task-attachments />
        <task-activity />
        <div style="height: 50px"></div>
      </div>
    </div>
    <div class="task-bar-right">
      <div class="task-menu-icons">
        <follow-task :show-legend="true" @followersUpdate="updateFollowers" :task="task"/>
        <task-menu-right />
      </div>
      <div class="line2-bar">
        <div class="flex justify-between">
          <task-status-btn />
        </div>
        <task-detalhes />
      </div>
<!--      <div style="height: 2000px"></div>-->
    </div>
  </div>
  <router-view v-else />
</template>
