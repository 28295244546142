<template>
  <div class="assigned-input">
    <person-select v-if="mounted" class="es-simple" v-model="model" :disable="disable" />
    <div v-if="!disable && model" @click="unassign" class="unassign"><a>Desatribuir</a></div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: "ClienteEdit",
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: null,
      bind: false,
      mounted: true
    }
  },
  computed: {
    entity () {
      return this.task.cliente
    }
  },
  mounted() {
    this.model = this.task.cliente
    this.$nextTick(() => {
      this.bind = true
    })
  },
  watch: {
    model (v) {
      this.bind && this.change(v)
    }
  },
  methods: {
    set (v) {
      this.task.cliente = v
    },
    reset (v) {
      this.bind = false
      this.mounted = false
      this.$nextTick(() => {
        this.model = v
        this.set(v)
        this.$nextTick(() => {
          this.bind = true
          this.mounted = true
        })
      })
    },
    change (v) {
      const old = this.entity
      this.$uloc.dialog({
        title: 'Confirmar alteração de cliente',
        message: 'Alterar cliente da tarefa para ' + v.name + '?',
        ok: 'Alterar cliente',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(v)
      })
          .catch(() => {
            this.reset(old)
          })
    },
    confirmUpdate (person) {
      const old = this.entity
      this.loading = true
      updateTask({
        id: this.task.id,
        cliente: person ? person.id : null
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.$emit('update', person)
          })
          .catch(error => {
            this.loading = false
            this.set(old)
            this.model = old
            this.alertApiError(error)
          })
      if (!person) {
        this.mounted = false
        this.bind = false
        this.$nextTick(() => {
          this.model = null
          this.$nextTick(() => {
            this.mounted = true
          })
        })
      }
      this.set(person)
    },
    unassign () {
      this.$uloc.dialog({
        title: 'Confirmar alteração de cliente',
        message: 'Remover cliente para esta tarefa?',
        ok: 'Remover cliente',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(null)
      })
          .catch(() => {})
    }
  },
  components: {PersonSelect},
  mixins: [TaskMixin]
}
</script>
